const defaultValues = {
  deviceType: "",
  platformName: "",
  platformVersion: "",
  userAgent: "",
  requestHref: "",
  requestHostname: ""
};

export default function setBrowser() {
  const valuesFromBrowser = {
    userAgent: navigator.userAgent || "Unknown",
    requestHref: window.location.href || "Unknown",
    requestHostname: window.location.hostname || "Unknown"
  };
  return {
    ...defaultValues,
    ...valuesFromBrowser
  };
}
