import * as Yup from "yup";
import _ from "lodash";

function validatePerType(acc, field) {
  if (!field.required[0]) {
    //field not requiered, no validation needed.
    return acc;
  }
  switch (field.type) {
    case "text":
      acc[field.fieldname] = Yup.string()
        .trim()
        .min(field.min[0], field.min[1])
        .max(field.max[0], field.max[1])
        .required(field.required[1]);
      return acc;
    case "select":
      acc[field.fieldname] = Yup.string().required(field.required[1]);
      return acc;
    case "boolean":
      acc[field.fieldname] = Yup.boolean()
        .test("accept-terms", field.required[1], value => value === true)
        .required(field.required[1]);
      return acc;
    case "checkbox":
      acc[field.fieldname] = Yup.array()
        .of(Yup.string())
        .required(field.required[1]);
      return acc;
    case "email":
      acc[field.fieldname] = Yup.string()
        .email(field.required[1])
        .required(field.required[1]);
      return acc;
    case "phone":
      acc[field.fieldname] = Yup.string()
        .matches(/(^[0-9]+$)/, "Solo utilice dígitos.")
        .min(field.min[0], field.min[1])
        .max(field.max[0], field.max[1])
        .required(field.required[1]);
      return acc;
    case "number":
      acc[field.fieldname] = Yup.number()
        .required(field.required[1])
        .positive()
        .integer();
      return acc;
    default:
      return acc;
  }
}

function handleTypeComp(fields) {
  return _.reduce(fields, (acc, field) => validatePerType(acc, field), {});
}

export default function ValidationSchema(fieldsDef) {
  const yupShapeObj = _.reduce(
    fieldsDef,
    (acc, field) => {
      if (field.type === "config") {
        return acc;
      }
      if (field.type === "component") {
        const compFields = handleTypeComp(field.fields);
        return {
          ...acc,
          ...compFields
        };
      }
      return validatePerType(acc, field);
    },
    {}
  );
  return Yup.object().shape(yupShapeObj);
}
