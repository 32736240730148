import React from "react";
import mainImg from "./assets/criteria-10.1-rsp.jpg";

export default function Mobile({ common: { data } }) {
  return (
    <div className="layout-pic mobile">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt1">
              Para continuar compártenos tus datos restantes
            </div>
            <div className="next-step">
              <a href="#" alt="next">
                Siguiente paso
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
