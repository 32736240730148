import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import axios from "axios";
import endpoint from "../../config/endpoint";
import { LoadingBtn } from "../Loaders";

//Bootstrap
import Form from "react-bootstrap/Form";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
      loading: {
        estados: false,
        municipios: false,
        asentamientos: false,
        codigoPostal: false
      },
      listaEstados: [],
      listaMunicipios: [],
      listaAsentamientos: [],
      codEstado: "",
      codMunicipio: "",
      codAsentamiento: "",
      codigoPostal: "",
      validated: false
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.fetchEstados();
  }

  async fetchCodigoPostal() {
    const { codEstado, codMunicipio, codAsentamiento } = this.state;
    try {
      this.setState({
        ...this.state,
        loading: {
          codigoPostal: true
        }
      });
      const response = await axios.post(`${endpoint}/getPostalCode`, {
        codEstado,
        codMunicipio,
        codAsentamiento
      });
      const { status, dataLayer } = response.data;
      if (dataLayer) {
        this.props.GlobalStore.handleDataLayer(response.data.dataLayer);
      }
      if (status === "OK") {
        const { codigoPostal } = response.data;
        this.props.GlobalStore.formik.setFieldValue(
          "codigoPostal",
          codigoPostal
        );
        return this.setState({
          codigoPostal,
          validated: true,
          loading: {
            codigoPostal: false
          }
        });
      }
      if (status === "ERR") {
        return this.props.GlobalStore.handleErrFromServer(response.data.msg);
      }
    } catch (error) {
      return this.props.GlobalStore.handleErrFromServer(error);
    }
  }
  async fetcher(url, params, loader) {
    this.setState({
      ...this.state,
      loading: {
        [loader]: true
      }
    });
    try {
      const response = await axios.post(url, params);
      const { status, dataLayer, data } = response.data;
      if (dataLayer) {
        this.props.GlobalStore.handleDataLayer(response.data.dataLayer);
      }
      if (status === "OK") {
        this.setState({
          ...this.state,
          loading: {
            estados: false,
            municipios: false,
            asentamientos: false
          }
        });
        return data;
      }
      if (status === "ERR") {
        return this.props.GlobalStore.handleErrFromServer(response.data.msg);
      }
    } catch (error) {
      return this.props.GlobalStore.handleErrFromServer(error);
    }
  }

  async fetchEstados() {
    const listaEstados = await this.fetcher(
      `${endpoint}/getListaEstados`,
      {},
      "estados"
    );
    return this.setState({
      listaEstados: _.sortBy(listaEstados, ["cod_estado"])
    });
  }

  async fetchMunicipios(estado) {
    if (estado === "") {
      return null;
    }
    const listaMunicipios = await this.fetcher(
      `${endpoint}/getListaMunicipios`,
      {
        estado
      },
      "municipios"
    );
    return this.setState({
      listaMunicipios: listaMunicipios.sort((a, b) =>
        a["des_municipio"].localeCompare(b["des_municipio"])
      )
    });
  }

  async fetchAsentamientos(municipio) {
    if (municipio === "") {
      return null;
    }
    const listaAsentamientos = await this.fetcher(
      `${endpoint}/getListaAsentamientos`,
      {
        estado: this.state.codEstado,
        municipio
      },
      "asentamientos"
    );
    return this.setState({
      listaAsentamientos: listaAsentamientos.sort((a, b) =>
        a["des_acenta"].localeCompare(b["des_acenta"])
      )
    });
  }

  async handleOnChange({ target: { value, name } }) {
    await this.setState({
      [name]: value
    });
    const finder = {
      estado: () => {
        const matchEstado = _.find(this.state.listaEstados, {
          cod_estado: this.state.codEstado
        });
        if (!matchEstado) {
          return "";
        }
        return matchEstado.des_estado;
      },
      municipio: () => {
        const matchMun = _.find(this.state.listaMunicipios, {
          codigo_municipio: this.state.codMunicipio
        });
        if (!matchMun) {
          return "";
        }
        return matchMun.des_municipio;
      },
      asentamiento: () => {
        const matchAsen = _.find(this.state.listaAsentamientos, {
          codigo_asenta: this.state.codAsentamiento
        });
        if (!matchAsen) {
          return "";
        }
        return matchAsen.des_acenta;
      }
    };

    switch (name) {
      case "codEstado":
        this.setState({
          codMunicipio: "",
          codAsentamiento: "",
          codigoPostal: "",
          validated: false
        });
        this.props.GlobalStore.formik.setFieldValue("estado", finder.estado());
        return this.fetchMunicipios(value);
      case "codMunicipio":
        this.setState({
          codAsentamiento: "",
          codigoPostal: "",
          validated: false
        });
        this.props.GlobalStore.formik.setFieldValue(
          "municipio",
          finder.municipio()
        );
        return this.fetchAsentamientos(value);
      case "codAsentamiento":
        this.props.GlobalStore.formik.setFieldValue(
          "asentamiento",
          finder.asentamiento()
        );
        return this.fetchCodigoPostal(value);

      default:
        return null;
    }
  }

  buildFormWrapper(config) {
    const { list } = config;
    const { touched, errors } = this.props.GlobalStore.formik;
    list.splice(
      0,
      0,
      <option value="" defaultValue key="default">
        Seleccione una opción
      </option>
    );
    return (
      <Form.Group controlId={config.label}>
        <Form.Label>{config.label}</Form.Label>
        <Form.Control
          as="select"
          onChange={this.handleOnChange}
          name={config.name}
          value={config.value}
          isValid={
            touched[config.formikFieldname] && !errors[config.formikFieldname]
          }
          isInvalid={
            touched[config.formikFieldname] && !!errors[config.formikFieldname]
          }
        >
          {list}
        </Form.Control>
      </Form.Group>
    );
  }

  buildListEstados() {
    if (this.state.loading.estados) {
      return <LoadingBtn />;
    }
    const list = _.map(
      this.state.listaEstados,
      ({ cod_estado, des_estado }, i) => {
        return (
          <option key={`estado-${i}`} value={cod_estado}>
            {des_estado}
          </option>
        );
      }
    );
    const config = {
      label: "Estado",
      list,
      name: "codEstado",
      value: this.state.codEstado,
      formikFieldname: "estado"
    };
    return this.buildFormWrapper(config);
  }

  buildListMunicipios() {
    if (_.isEmpty(this.state.codEstado)) {
      return null;
    }
    if (this.state.loading.municipios) {
      return <LoadingBtn />;
    }
    const list = _.map(
      this.state.listaMunicipios,
      ({ codigo_municipio, des_municipio }, i) => {
        return (
          <option key={`municipio-${i}`} value={codigo_municipio}>
            {des_municipio}
          </option>
        );
      }
    );
    const config = {
      label: "Municipio / Delegación",
      list,
      name: "codMunicipio",
      value: this.state.codMunicipio,
      formikFieldname: "municipio"
    };
    return this.buildFormWrapper(config);
  }

  buildListAsentamiento() {
    if (_.isEmpty(this.state.codMunicipio)) {
      return null;
    }
    if (this.state.loading.asentamientos) {
      return <LoadingBtn />;
    }
    const list = _.map(
      this.state.listaAsentamientos,
      ({ codigo_asenta, des_acenta }, i) => {
        return (
          <option key={`asentamientos-${i}`} value={codigo_asenta}>
            {des_acenta}
          </option>
        );
      }
    );
    const config = {
      label: "Colonia",
      list,
      name: "codAsentamiento",
      value: this.state.codAsentamiento,
      formikFieldname: "asentamiento"
    };
    return this.buildFormWrapper(config);
  }

  getCodigoPostal() {
    if (this.state.loading.codigoPostal) {
      return <LoadingBtn />;
    }
    if (_.isEmpty(this.state.codigoPostal)) {
      return null;
    }
    return (
      <Form.Group controlId="formCodigoPostal">
        <Form.Label>Codigo Postal</Form.Label>
        <Form.Control type="text" disabled value={this.state.codigoPostal} />
      </Form.Group>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.buildListEstados()}
        {this.buildListMunicipios()}
        {this.buildListAsentamiento()}
        {this.getCodigoPostal()}
      </React.Fragment>
    );
  }
}

export default inject("GlobalStore")(observer(Select));
