import _ from "lodash";

function handleTypeComp(fields) {
  return _.reduce(
    fields,
    (acc, field) => {
      if (field.type === "boolean") {
        return {
          ...acc,
          [field.fieldname]: false
        };
      }
      return {
        ...acc,
        [field.fieldname]: ""
      };
    },
    {}
  );
}

export default function FormInitialValues(fieldsDef) {
  return _.reduce(
    fieldsDef,
    (acc, field) => {
      switch (field.type) {
        case "config":
          return acc;
        case "component":
          const compFields = handleTypeComp(field.fields);
          return {
            ...acc,
            ...compFields
          };
        case "hidden":
          return {
            ...acc,
            [field.fieldname]: field.default
          };
        case "checkbox":
          return {
            ...acc,
            [field.fieldname]: []
          };
        default:
          return {
            ...acc,
            [field.fieldname]: field.default || ""
          };
      }
    },
    {}
  );
}
