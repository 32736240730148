import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';

//helper
import YupValidationSchema from './helpers/YupValidationShema';
import FormInitValues from './helpers/FormInitValues';

//Bootstrap
import Form from 'react-bootstrap/Form';

//components
import FieldBuilder from './FieldBuilder';
import StepTitle from './StepTitle';
import Btn from '../Btn';
import Err from '../Err';
import FlashMsg from '../FlashMsg';
import { LoadingSection } from '../Loaders';

function MasterForm({ GlobalStore }) {
	const { err, loading, step, definition, setFormik, handleSubmit } = GlobalStore;

	const [ initialValues, setInitialValues ] = useState(null);
	const [ validationSchema, setValidationSchema ] = useState(null);

	useEffect(
		() => {
			setInitialValues(FormInitValues(definition));
			setValidationSchema(YupValidationSchema(definition));
		},
		[ definition ]
	);

	if (loading.server) {
		return <LoadingSection />;
	}
	if (err) {
		return <Err />;
	}
	if (!initialValues || !validationSchema) {
		return null;
	}
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				await handleSubmit();
				setSubmitting(false);
			}}
		>
			{(formik) => {
				setFormik(formik);
				return (
					<div className={`steps-container step-${step}`}>
						<FlashMsg />
						<Form onSubmit={formik.handleSubmit} noValidate>
							<StepTitle fieldsDef={definition} />
							<div className="fields-container">
								<FieldBuilder fieldsDef={definition} />
							</div>
							<Btn />
						</Form>
					</div>
				);
			}}
		</Formik>
	);
}

export default inject('GlobalStore')(observer(MasterForm));
