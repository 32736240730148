import React from "react";
import mainImg from "./assets/criteria-10.2.jpg";

export default function Desktop() {
  return (
    <div className="layout-pic desktop">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt2">Llámanos y continúa con tu solicitud</div>
            <div class="phone">
              <div>(33) 2100 - 6731</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
