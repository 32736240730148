import React from "react";
import { inject, observer } from "mobx-react";

function FinalScreen({ GlobalStore: { finalScreen } }) {
  if (!finalScreen) {
    return null;
  }
  localStorage.removeItem("requestId");
  return (
    <div className="final-screen">
      <h1 className="my-4">{finalScreen.h1}</h1>
      <hr />
      <h2 className="mb-2">{finalScreen.h2}</h2>
      <div className="excerpt">{finalScreen.excerpt}</div>
      {finalScreen.linkUrl ? (
        <a href={finalScreen.linkUrl}>{finalScreen.linkTitle}</a>
      ) : null}
    </div>
  );
}

export default inject("GlobalStore")(observer(FinalScreen));
