import queryString from "query-string";

const defaultValues = {
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_content: "",
  utm_term: ""
};

export default function getUTM() {
  if (window.location.search) {
    const parsed = queryString.parse(window.location.search);
    return {
      ...defaultValues,
      ...parsed
    };
  }
  return defaultValues;
}
