import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "mobx-react";

//Components
import CookiesScreen from "../CookiesScreen";
import MainContainer from "../MainContainer";
import StaticPages from "../StaticPages";

//Stores
import GlobalStore from "../../stores/global";

export default function App() {
  return (
    <div className="App">
      <Provider GlobalStore={GlobalStore}>
        <CookiesScreen />
        <Router>
          <Route path="/" exact component={MainContainer} />
          <Route
            path="/static/:criteria/:device"
            exact
            component={StaticPages}
          />
        </Router>
      </Provider>
    </div>
  );
}
