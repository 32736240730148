import React from "react";

//Bootstrap
import Form from "react-bootstrap/Form";

const Error = ({ touched, message }) => {
  if (!touched) {
    return null;
  }
  if (message) {
    return (
      <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
    );
  }
  return null;
};

export default Error;
