const defaultValues = {
  gclid: "",
  gclsrc: ""
};

export default function setGoogle() {
  return {
    ...defaultValues
  };
}
