import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

//Components
import { StaticHeader, Footer } from "../Theme";

//scss
import "./static-page.scss";
import "./layouts.scss";

//criteria
import criteriaArr from "./criteria";

//screens
import Criteria22 from "./screens/2_2";
import Criteria23 from "./screens/2_3";
import Criteria31 from "./screens/3_1";
import Criteria32 from "./screens/3_2";
import Criteria81 from "./screens/8_1";
import Criteria82 from "./screens/8_2";
import Criteria91 from "./screens/9_1";
import Criteria93 from "./screens/9_3";
import Criteria101 from "./screens/10_1";
import Criteria102 from "./screens/10_2";
import Criteria103 from "./screens/10_3";
import Criteria104 from "./screens/10_4";

export default function Static({ history }) {
  const { criteria, device } = useParams();

  const matched = _.find(criteriaArr, { criteria: Number(criteria) });
  const noMatch = _.isEmpty(matched);

  if (noMatch) {
    history.push("/");
    return null;
  }

  const customHead = {
    h1: matched.data.h1 || null,
    h2: matched.data.h2 || null,
    h3: matched.data.h3 || null,
  };

  const handleBuild = () => {
    switch (Number(criteria)) {
      case 2.2:
        return (
          <Criteria22
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 2.3:
        return (
          <Criteria23
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 3.1:
        return (
          <Criteria31
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 3.2:
        return (
          <Criteria32
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 8.1:
        return (
          <Criteria81
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 8.2:
        return (
          <Criteria82
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 9.1:
        return (
          <Criteria91
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 9.3:
        return (
          <Criteria93
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 10.1:
        return (
          <Criteria101
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 10.2:
        return (
          <Criteria102
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 10.3:
        return (
          <Criteria103
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      case 10.4:
        return (
          <Criteria104
            criteria={criteria}
            device={device}
            common={criteriaArr[0]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="App static-page">
      <StaticHeader customHead={customHead} />
      <div className="wrapper content-wrapper">{handleBuild()}</div>
      <Footer />
    </div>
  );
}
