import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import axios from "axios";
import endpoint from "../../config/endpoint";
import { LoadingBtn } from "../Loaders";

//Bootstrap
import Form from "react-bootstrap/Form";

//Components
import Error from "../MasterForm/FieldBuilder/Error";

function ZipCode({ GlobalStore }) {
  const [loading, setLoading] = useState(false);
  const [listaAsentamientos, setListaAsentamientos] = useState([]);

  const {
    formik,
    handleDataLayer,
    handleErrFromServer,
    setFlashMsg
  } = GlobalStore;

  const handleRequest = async codigoPostal => {
    try {
      setLoading(true);
      const response = await axios.post(`${endpoint}/reqPostalCodeData`, {
        codigoPostal
      });
      const { status, dataLayer } = response.data;
      if (dataLayer) {
        handleDataLayer(response.data.dataLayer);
      }
      if (status === "OK") {
        const { estado, municipio, asentamientos } = response.data;
        formik.setValues({
          ...formik.values,
          codigoPostal,
          estado,
          municipio
        });
        const asentamientosSorted = asentamientos.sort((a, b) =>
          a["descripcionAsentamiento"].localeCompare(
            b["descripcionAsentamiento"]
          )
        );
        setListaAsentamientos(asentamientosSorted);
        setLoading(false);
      }
      if (response.data.status === "ERR") {
        setLoading(false);
        setFlashMsg(response.data.msg);
        return formik.setValues({
          ...formik.values,
          codigoPostal: "",
          estado: "",
          municipio: ""
        }); //reset values
      }
    } catch (error) {
      return handleErrFromServer(error);
    }
  };
  const handleOnChange = (name, value) => {
    formik.setFieldValue(name, value);
    //only nums
    const regex = /[0-9]/;
    if (!regex.test(value) && value !== "") {
      return null;
    }
    if (value.length === 5) {
      return handleRequest(value);
    }
  };

  const buildFormWrapper = config => {
    const { list } = config;
    list.splice(
      0,
      0,
      <option value="" defaultValue key="default">
        Seleccione una opción
      </option>
    );
    return (
      <Form.Group controlId="formAsentamiento">
        <Form.Label>{config.label}</Form.Label>
        <Form.Control
          as="select"
          onChange={formik.handleChange}
          name={config.name}
          value={config.value}
        >
          {list}
        </Form.Control>
      </Form.Group>
    );
  };

  const buildListAsentamiento = () => {
    if (
      _.isEmpty(listaAsentamientos) ||
      _.isEmpty(formik.values["municipio"])
    ) {
      return null;
    }
    const list = _.map(listaAsentamientos, ({ descripcionAsentamiento }, i) => {
      return (
        <option key={`asentamientos-${i}`} value={descripcionAsentamiento}>
          {descripcionAsentamiento}
        </option>
      );
    });
    const config = {
      label: "Colonia",
      list,
      name: "asentamiento",
      value: formik.values["asentamiento"]
    };
    return buildFormWrapper(config);
  };

  const buildEstadoMunicipio = () => {
    if (loading) {
      return <LoadingBtn />;
    }
    return (
      <React.Fragment>
        <Form.Group controlId="formEstado">
          <Form.Label>
            Estado<span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            disabled
            value={formik.values["estado"]}
          />
        </Form.Group>
        <Form.Group controlId="formMunicipio">
          <Form.Label>
            Municipio / Delegación<span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            disabled
            value={formik.values["municipio"]}
          />
        </Form.Group>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Form.Group controlId="formPostal">
        <Form.Label>
          Código postal<span className="required-field">*</span>
        </Form.Label>

        <Form.Control
          required
          type="text"
          maxLength="5"
          name="codigoPostal"
          value={formik.values["codigoPostal"]}
          onChange={e => handleOnChange(e.target.name, e.target.value)}
          onBlur={formik.handleBlur}
          isValid={
            formik.touched["codigoPostal"] && !formik.errors["codigoPostal"]
          }
          isInvalid={
            formik.touched["codigoPostal"] && !!formik.errors["codigoPostal"]
          }
        />
        <Error
          touched={formik.touched["codigoPostal"]}
          message={formik.errors["codigoPostal"]}
        />
      </Form.Group>

      {buildEstadoMunicipio()}
      {buildListAsentamiento()}
    </React.Fragment>
  );
}

export default inject("GlobalStore")(observer(ZipCode));
