import React from "react";

//Components
import ValidateAddress from "../../ValidateAddress";
import AcceptTerms from "../../AcceptTerms";

export default function Comp({ name }) {
  switch (name) {
    case "AcceptTerms":
      return <AcceptTerms key="AcceptTerms" />;
    case "ValidateAddress":
      return <ValidateAddress key="ValidateAddress" />;
    default:
      return null;
  }
}
