import React from "react";

import pasos from "../../images/pasos.png";
import icoApp from "../../images/icono-apps.png";
import icoBaz from "../../images/icono-bazteca.png";
import icoInstall from "../../images/icono-instalar.png";
import icoOfertas from "../../images/icono-ofertas.png";

export default function Desktop({ common: { data } }) {
  return (
    <div className="layout-steps desktop">
      <div className="excerpt1">¡Pero tenemos buenas noticias para ti!</div>
      <div className="excerpt2">
        Te invitamos a realizar tu solicitud con uno de nuestros socios
        <img src={data.logoAppSmall} className="inline-logo" alt="logo" />
        <br />
        Sigue las instrucciones para descargar la app y continuar con el proceso
        desde tu móvil:
      </div>
      <img src={pasos} alt="steps" className="steps-img" />
      <ul className="steps">
        <li>
          Ingresa a tu tienda de apps preferida
          <img src={icoApp} alt="ico" />
        </li>
        <li>
          Busca Banco Azteca <img src={icoBaz} alt="ico" />
        </li>
        <li>
          Selecciona Instalar
          <img src={icoInstall} alt="ico" />
        </li>
        <li>
          Sigue los pasos para registrarte y ver sus ofertas de crédito{" "}
          <img src={icoOfertas} alt="ico" />
        </li>
      </ul>
    </div>
  );
}
