import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import Error from "./Error";
import { FieldArray } from "formik";

//Bootstrap
import Form from "react-bootstrap/Form";

function Checkbox({ field, GlobalStore: { formik } }) {
  const { fieldname, options, label } = field;
  const { values, errors } = formik;
  const renderOptions = arrayHelpers => {
    return _.map(options, (option, index) => {
      return (
        <Form.Check
          inline
          type="checkbox"
          name={option.value}
          label={option.label}
          id={`inline-${index}`}
          onChange={e => {
            if (e.target.checked) {
              return arrayHelpers.push(option.value);
            }
            const valueIndex = values[fieldname].indexOf(option.value);
            return arrayHelpers.remove(valueIndex);
          }}
        />
      );
    });
  };
  return (
    <Form.Group controlId={fieldname}>
      <Form.Label>{label}</Form.Label>
      <FieldArray
        name={fieldname}
        render={arrayHelpers => (
          <div className="checkboxes-container">
            {renderOptions(arrayHelpers)}
            <Error touched={true} message={errors[fieldname]} />
          </div>
        )}
      />
    </Form.Group>
  );
}

export default inject("GlobalStore")(observer(Checkbox));
