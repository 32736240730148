import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function Btn({ GlobalStore: { isLastStep, formik } }) {
  if (formik.isSubmitting) {
    return (
      <Button
        variant="success"
        size="lg"
        disabled
        block
        className="my-4"
        style={{ pointerEvents: "none" }}
      >
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Cargando...
      </Button>
    );
  }
  if (!_.isEmpty(formik.errors)) {
    return (
      <Button
        disabled
        size="lg"
        block
        style={{ pointerEvents: "none" }}
        className="my-4"
      >
        Complete los campos requeridos
      </Button>
    );
  }
  return (
    <Button size="lg" block type="submit" className="my-4">
      Continuar
    </Button>
  );
}

export default inject("GlobalStore")(observer(Btn));
