import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

//static assets
import siteTxt from "../../assets/siteTxt.json";
import mainImg from "../../assets/images/main.jpg";
import step1 from "../../assets/icons/ico1-request.svg";
import step2 from "../../assets/icons/ico2-checklist.svg";

function MainImg({ GlobalStore }) {
  useEffect(() => {
    GlobalStore.handleDataLayer([{ event: "optimize.activate" }]);
  }, [GlobalStore]);
  return (
    <div className="information">
      <div className="img-main" style={{ backgroundImage: `url(${mainImg})` }}>
        <div className="excerpt">
          <h3>{siteTxt.h3}</h3>
          <p>{siteTxt.excerpt}</p>
        </div>
      </div>
      <div className="steps">
        <h3>{siteTxt.steps.title}</h3>
        <ul>
          <li>
            <span className="step">1</span>
            <span
              className="ico"
              style={{ backgroundImage: `url(${step1})` }}
            />
            <span>{siteTxt.steps.step1 || null}</span>
          </li>
          <li>
            <span className="step">2</span>
            <span
              className="ico"
              style={{ backgroundImage: `url(${step2})` }}
            />
            <span>{siteTxt.steps.step2 || null}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default inject("GlobalStore")(observer(MainImg));
