import React from "react";

export default function Mobile({ common: { data } }) {
  return (
    <div className="layout-qr mobile">
      <hr />
      <div className="excerpt2">
        Estás a un paso de obtener tu dinero de inmediato
      </div>
      <div className="excerpt3">
        Llámanos y conoce nuestras opciones de crédito:
      </div>
      <div className="phone">
        <a href={`tel:${data.phone}`}>Quiero llamar ahora</a>
      </div>
      <p>
        También puedes realizar tu solicitud con uno de nuestros socios.
        Descarga la app para continuar con el proceso
      </p>
      <div className="app-link">
        <a href={data.appApproved}>
          <img src={data.logoApp} alt="app" />
        </a>
      </div>
    </div>
  );
}
