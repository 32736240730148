import React from "react";
import _ from "lodash";

export default function StepTitle({ fieldsDef }) {
  const handleTitle = () => {
    const configObj = _.find(fieldsDef, { type: "config" });
    if (!configObj) {
      return null;
    }
    return <h3 className="mb-4">{configObj.title}</h3>;
  };
  return handleTitle();
}
