import React from "react";
import mainImg from "./assets/criteria-2.2-rsp.jpg";

export default function Mobile({ common: { data } }) {
  return (
    <div className="layout-pic mobile">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt1">
              <span>Actualmente estamos trabajando en tu solicitud.</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="excerpt2">Si tienes alguna duda siempre puedes llamarnos</div>
      <div class="phone">
        <a href={`tel:${data.phone}`}>Quiero llamar ahora</a>
      </div>
    </div>
  );
}
