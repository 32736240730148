import React from "react";
import mainImg from "./assets/criteria-8.1-rsp.jpg";

export default function Mobile({ common: { data } }) {
  return (
    <div className="layout-pic mobile">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt1">
              <span>Gracias por confiar en nosotros</span>
            </div>
            <div class="excerpt2">¿Tienes dudas de tu solicitud?</div>
            <div className="phone">
              <a href={`tel:${data.phone}`}>Llámanos</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
