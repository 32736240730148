import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import Button from "react-bootstrap/Button";

function CookiesScreen({ GlobalStore: { setGaClientId, session, loading } }) {
  const [acceptCookies, setAcceptCookies] = useState(false);

  useEffect(() => {
    syncWithLocalStorage();
  }, []);

  async function handleOnClick() {
    localStorage.setItem("acceptCookies", "true");
    setGaClientId();
    return setAcceptCookies(true);
  }

  function syncWithLocalStorage() {
    if (localStorage.getItem("acceptCookies")) {
      setAcceptCookies(true);
    }
    return null;
  }

  if (session.gaClientId || acceptCookies) {
    return null;
  }

  if (loading.server) {
    return null;
  }

  return (
    <div className="accept-cookies">
      <div className="wrapper">
        <p>
          Utilizamos cookies propias y de terceros para mejorar nuestros
          servicios y mostrar a los usuarios publicidad relacionada con sus
          preferencias. Si se continúa navegando, consideramos que se acepta su
          uso.
        </p>
        <Button variant="success" size="lg" onClick={() => handleOnClick()}>
          Aceptar
        </Button>
      </div>
    </div>
  );
}

export default inject("GlobalStore")(observer(CookiesScreen));
