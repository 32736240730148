import React from "react";
import mainImg from "./assets/criteria-2.2.jpg";

export default function Desktop({ common: { data } }) {
  return (
    <div className="layout-pic desktop">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt1">
              <span>Actualmente estamos trabajando en tu solicitud.</span>
            </div>
            <div class="excerpt2">
              Si tienes alguna duda siempre puedes llamarnos:
            </div>
            <div class="phone">{data.phoneMask}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
