import React from "react";
import mainImg from "./assets/criteria-9.1.jpg";

export default function Desktop() {
  return (
    <div className="layout-pic desktop">
      <div className="wrapper">
        <div className="main-img">
          <img src={mainImg} alt="" />
        </div>
        <div className="text-container">
          <div class="text-wrapper">
            <div class="excerpt1">
              <span>Tenemos más opciones para ti</span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-bottom">
        <div class="excerpt1">Comunícate con nosotros</div>
        <div class="phone">
          <div>(33) 2100 - 6731</div>
        </div>
      </div>
    </div>
  );
}
