import _ from "lodash";

export default function handleDataLayer(events) {
  return _.map(events, event => window.dataLayer.push(event));
}

export const dataLayerSubmissionReq = data => {
  const event = "evSubmissionRequest";
  const { values, ...cleanData } = data;
  switch (data.step) {
    case 1:
      const { telefonoTipo } = values;
      const newValues = {
        telefonoTipo
      };
      return window.dataLayer.push({
        event,
        ...cleanData,
        ...newValues
      });
    default:
      return window.dataLayer.push({
        event,
        ...cleanData,
        ...values
      });
  }
};
