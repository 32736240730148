import _ from "lodash";

//firebase storage
import { storage } from "../../firebase";

export default async function handleDocs(docs) {
  const docsArr = _.map(docs, async ({ name, path }) => {
    try {
      return {
        name,
        url: await storage.ref(`/docs/${path}`).getDownloadURL()
      };
    } catch (error) {
      console.log(error);
      return [];
    }
  });
  const allDocs = await Promise.all(docsArr);
  return allDocs;
}
