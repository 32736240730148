import _ from "lodash";

//firebase storage
import { storage } from "../../firebase";

export default async function handleStyles(styles) {
  const stylesArr = _.map(styles, async (style) => {
    try {
      return await storage.ref(`/css/${style}`).getDownloadURL();
    } catch (error) {
      console.log(error);
      return [];
    }
  });
  const allstyles = await Promise.all(stylesArr);
  return allstyles;
}
