import React from "react";
import { inject, observer } from "mobx-react";
import Error from "./Error";

//Bootstrap
import Form from "react-bootstrap/Form";

function Text({ field, GlobalStore: { formik } }) {
  const { fieldname, label } = field;
  const { handleChange, handleBlur, values, touched, errors } = formik;
  return (
    <Form.Group controlId={fieldname}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        required
        type="text"
        name={fieldname}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[fieldname]}
        isValid={touched[fieldname] && !errors[fieldname]}
        isInvalid={touched[fieldname] && !!errors[fieldname]}
      />
      <Error touched={touched[fieldname]} message={errors[fieldname]} />
    </Form.Group>
  );
}

export default inject("GlobalStore")(observer(Text));
