import React from "react";

import Desktop from "./desktop";
import Mobile from "./mobile";

export default function index({ device, common }) {
  switch (device) {
    case "desktop":
      return <Desktop common={common} />;
    case "mobile":
      return <Mobile common={common} />;
    default:
      return null;
  }
}
