import React from "react";
import { inject, observer } from "mobx-react";

function Footer({ GlobalStore: { site } }) {
  return (
    <footer className="bg-black py-5">
      <div className="container text-center">
        <div className="row pb-4">
          <div className="col-sm-4 col-md-3">
            <ul className="list">
              <li>
                <a href="https://wini.mx/blog/">Blog</a>
              </li>
              <li>
                <a href="https://wini.mx/prestamos-personales">
                  Préstamos personales
                </a>
              </li>
              <li>
                <a href="https://wini.mx/sanadores-de-deudas">
                  Sanadores de deuda
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-3 pb-20">
            <ul className="list">
              <li>
                <a href="https://wini.mx/terminos-y-condiciones">
                  Términos y condiciones
                </a>
              </li>
              <li>
                <a href="https://wini.mx/aviso-de-privacidad">
                  Aviso de privacidad
                </a>
              </li>
              <li>
                <a href="https://wini.mx/preguntas-frecuentes">
                  Preguntas frecuentes
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-3 col-md-offset-1">
            <ul className="list last">
              <li>
                <a href="https://wini.mx/anuncia-tu-marca">
                  Quiero anunciar mi marca
                </a>
              </li>
              <li>
                <a href="https://wini.mx/contacto">Contacto</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2 mb-4">
            <p>Síguenos en:</p>
            <ul className="soc mb-20 dark-bg">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/winimexico/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p>
          Nuestra oficina registrada: Av. Paseo de las Palmas 735, Lomas de
          Chapultepec I Secc, Miguel Hidalgo, 11000 Ciudad de México, CDMX.
        </p>
        <p>
          2020 © wini.mx - Todos los derechos reservados. Al usar este sitio,
          aceptas nuestra política de privacidad, así como nuestros términos y
          condiciones.
        </p>
      </div>
    </footer>
  );
}

export default inject("GlobalStore")(observer(Footer));
