import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";

import Error from "../MasterForm/FieldBuilder/Error";

//Bootstrap
import Form from "react-bootstrap/Form";

function AcceptTerms({
  GlobalStore: { loading, site, definition, config, step, formik },
}) {
  if (loading.data || loading.server) {
    return null;
  }
  if (!config.showAcceptTerms) {
    return null;
  }
  if (step !== 1) {
    return null;
  }
  const termsDoc = _.find(site.docs, ["name", "terms"]);
  const privacyDoc = _.find(site.docs, ["name", "privacy"]);
  const compTerms = _.find(definition, ["name", "AcceptTerms"]);
  const compPrivacy = _.find(definition, ["name", "AcceptPrivacy"]);
  return (
    <div className="accept-terms rounded shadow-sm p-3">
      <Form.Group controlId="terms">
        <Form.Check
          type="checkbox"
          name="terms"
          checked={formik.values.terms}
          onChange={formik.handleChange}
          label={compTerms.fields[0].label}
          isValid={formik.touched.terms && !formik.errors.terms}
          isInvalid={formik.touched.terms && !!formik.errors.terms}
        />
      </Form.Group>
      <Form.Group controlId="privacy">
        <Form.Check
          type="checkbox"
          name="privacy"
          checked={formik.values.privacy}
          onChange={formik.handleChange}
          label={compPrivacy.fields[0].label}
          isValid={formik.touched.privacy && !formik.errors.privacy}
          isInvalid={formik.touched.privacy && !!formik.errors.privacy}
        />
      </Form.Group>
      <div className="more">
        <a target="_blank" rel="noopener noreferrer" href={termsDoc.url}>
          Terminos y condiciones
        </a>
        {" | "}
        <a target="_blank" rel="noopener noreferrer" href={privacyDoc.url}>
          Aviso de privacidad
        </a>
        <Error touched={formik.touched.terms} message={formik.errors.terms} />
      </div>
    </div>
  );
}

export default inject("GlobalStore")(observer(AcceptTerms));
