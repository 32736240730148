import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";

import { Helmet } from "react-helmet";

//Components
import MasterForm from "../MasterForm";
import FinalScreen from "../FinalScreen";
import { Header, Footer, MainImg } from "../Theme";

function MainContainer({
  GlobalStore: {
    loading,
    site,
    config: { showFinalScreen }
  }
}) {
  const handleStyles = () => {
    if (!site.styles) {
      return null;
    }
    return _.map(site.styles, style => <link rel="stylesheet" href={style} />);
  };
  return (
    <React.Fragment>
      {!loading.data && (
        <Helmet>
          <title>{site.title}</title>
          <meta name="description" content={site.description} />
          {handleStyles()}
        </Helmet>
      )}
      <Header />
      <div className="wrapper content-wrapper">
        <div className="grid-container">
          <MainImg />
          {showFinalScreen ? <FinalScreen /> : <MasterForm />}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default inject("GlobalStore")(observer(MainContainer));
