import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoadingBtn = () => {
  return <Spinner animation="grow" />;
};

const LoadingSection = () => {
  return (
    <div className="loader-container ">
      <Spinner animation="border" />
    </div>
  );
};

export { LoadingBtn, LoadingSection };
