const defaultValues = {
  afiliadoId: "",
  deviceId: "",
  gaClientId: "",
  requestId: "",
  sessionId: "",
  leadId: "",
  productoId: "general"
};

export default function syncWithLocalStorage() {
  const valuesFromLocalStorage = {
    deviceId: localStorage.getItem("deviceId") || "",
    gaClientId: localStorage.getItem("ga_client_id") || "",
    requestId: localStorage.getItem("requestId") || ""
  };
  return {
    ...defaultValues,
    ...valuesFromLocalStorage
  };
}
