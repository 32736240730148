import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import Error from "./Error";

//Bootstrap
import Form from "react-bootstrap/Form";

function Select({ field, GlobalStore: { formik } }) {
  const { fieldname, options, label } = field;
  const { handleChange, handleBlur, values, touched, errors } = formik;
  const handleOptions = options => {
    const optionsBuilder = _.map(options, ({ value, label }) => (
      <option value={value} key={value}>
        {label}
      </option>
    ));
    optionsBuilder.splice(
      0,
      0,
      <option value="" defaultValue key="default">
        Seleccione una opción
      </option>
    );
    return optionsBuilder;
  };
  return (
    <Form.Group controlId={fieldname}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        name={fieldname}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[fieldname]}
        isValid={touched[fieldname] && !errors[fieldname]}
        isInvalid={touched[fieldname] && !!errors[fieldname]}
      >
        {handleOptions(options)}
      </Form.Control>
      <Error touched={touched[fieldname]} message={errors[fieldname]} />
    </Form.Group>
  );
}

export default inject("GlobalStore")(observer(Select));
