import React from "react";
import aztecaLogo from "../../images/logo-baz-peq.png";

export default function Mobile({ common: { data } }) {
  return (
    <div className="layout-steps mobile">
      <hr />
      <div className="excerpt2">
        Estás a un paso de obtener tu dinero de inmediato
      </div>
      <div className="excerpt3">
        Llámanos y conoce nuestras opciones de crédito:
      </div>
      <div className="phone">
        <a href={`tel:${data.phone}`}>Quiero llamar ahora</a>
      </div>
      <p>También puedes realizar tu solicitud con uno de nuestros socios.</p>
      <div className="azteca-logo-mobile">
        <img src={aztecaLogo} alt="banco azteca logo" />
      </div>
      <br />
      <br />
      <p>Descarga la app para continuar con el proceso:</p>
      <div className="app-download">
        <a href={data.appApproved}>Quiero descargar la App</a>
      </div>
    </div>
  );
}
