import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

//components
import UsingSelectors from './UsingSelectors';
import UsingZipCode from './UsingZipCode';

//css
import './validate-address.scss';

//Bootstrap
import Nav from 'react-bootstrap/Nav';

function ValidateAddress({ GlobalStore: { config, step, formik } }) {
	const [ type, setType ] = useState('zip');

	const options = () => {
		const handleSelect = (option) => {
			formik.setValues({
				...formik.values,
				codigoPostal: '',
				estado: '',
				municipio: ''
			});
			return setType(option);
		};
		return (
			<Nav fill variant="tabs" defaultActiveKey="zipcode">
				<Nav.Item>
					<Nav.Link onClick={() => handleSelect('zip')} eventKey="zipcode">
						Usar Código Postal
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link onClick={() => handleSelect('select')} eventKey="select">
						Indicar Estado
					</Nav.Link>
				</Nav.Item>
			</Nav>
		);
	};
	const handleRender = () => {
		switch (type) {
			case 'zip':
				return <UsingZipCode key="zipcode" />;
			case 'select':
				return <UsingSelectors key="selector" />;
			default:
				return null;
		}
	};
	if (!config.showValidateAddress) {
		return null;
	}
	if (step !== 2) {
		return null;
	}
	return (
		<div className="validate-address rounded shadow-sm p-4 mb-5">
			{/* <div className="tabs-head">{options()}</div> */}
			<div className="tabs-body border border-top-0 rounded-bottom p-4">{handleRender()}</div>
		</div>
	);
}

export default inject('GlobalStore')(observer(ValidateAddress));
