import React from "react";
import _ from "lodash";

//Field Types
import Text from "./Text";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Email from "./Email";
import Number from "./Number";
import Phone from "./Phone";
import Comp from "./Comp";

export default function FieldBuilder({ fieldsDef }) {
  const handleFieldsPerType = () => {
    return _.map(fieldsDef, field => {
      const { type, fieldname, name } = field;
      switch (type) {
        case "text":
          return <Text field={field} key={fieldname} />;
        case "email":
          return <Email field={field} key={fieldname} />;
        case "number":
          return <Number field={field} key={fieldname} />;
        case "phone":
          return <Phone field={field} key={fieldname} />;
        case "select":
          return <Select field={field} key={fieldname} />;
        case "checkbox":
          return <Checkbox field={field} key={fieldname} />;
        case "component":
          return <Comp name={name} key={name} />;
        default:
          return null;
      }
    });
  };

  return <React.Fragment>{handleFieldsPerType()}</React.Fragment>;
}
