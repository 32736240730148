import React from "react";
import { inject, observer } from "mobx-react";
import Alert from "react-bootstrap/Alert";

function Err({ GlobalStore: { msg } }) {
  return (
    <div style={{ padding: "5%" }}>
      <h1 className="mb-4">¡Lo sentimos!</h1>
      <Alert variant="danger">
        <Alert.Heading>Ha ocurrido un error.</Alert.Heading>
        <p>{msg}</p>
      </Alert>
      <small>Puedes intentar usando otro navegador.</small>
    </div>
  );
}

export default inject("GlobalStore")(observer(Err));
